const ikejimeMethods = [
  {
    key: '1',
    value: 101,
    name: 'ikejime_method1',
    text: '氷締め（真水）',
  },
  {
    key: '2',
    value: 102,
    name: 'ikejime_method2',
    text: '氷締め（塩水）',
  },
  {
    key: '3',
    value: 103,
    name: 'ikejime_method3',
    text: '脳天締め',
  },
  {
    key: '5',
    value: 106,
    name: 'ikejime_method6',
    text: '鬼締め（濃度100％冷海水）',
  },
  {
    key: '5',
    value: 105,
    name: 'ikejime_method5',
    text: '活魚',
  },
  {
    key: '4',
    value: 104,
    name: 'ikejime_method4',
    text: '不明',
  },
];

export const ikejimeMethodNames = ikejimeMethods.map(({ name }) => name);

export default ikejimeMethods;
